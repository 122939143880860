var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.label)?_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:_vm.$style.inner},[_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-class":_vm.rowClassControl,"mobile-breakpoint":0,"show-expand":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.history",fn:function(ref){
var history = ref.item.history;
return [_vm._v(" Версия "+_vm._s((history.length || 0) + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[(item.pending)?_c('VBtn',{staticClass:"ml-2",attrs:{"depressed":"","x-small":"","disabled":""}},[_vm._v("Ожидается поднятие архива")]):[(item.value)?_c('VBtn',{staticClass:"ml-2",attrs:{"depressed":"","x-small":""}},[_vm._v("Скачать")]):_c('VBtn',{staticClass:"ml-2",attrs:{"depressed":"","x-small":""}},[_vm._v("Запросить из архива")])],(item.message)?_c('VBtn',{staticClass:"ml-2",attrs:{"depressed":"","x-small":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v("Исправить")]):_vm._e()],2)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var history = ref.item.history;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('VTimeline',{attrs:{"align-top":"","dense":""}},_vm._l((history),function(item,index){return _c('VTimelineItem',{key:item.id,attrs:{"small":""}},[_c('div',[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(item.date))]),_vm._v(" @"+_vm._s(item.author)+" ")]),_c('div',[_vm._v(_vm._s(item.message))]),_c('div',{staticClass:"mt-2"},[(item.pending)?_c('VBtn',{staticClass:"mr-2",attrs:{"depressed":"","x-small":"","disabled":""}},[_vm._v("Ожидается поднятие архива")]):[(item.value)?_c('VBtn',{staticClass:"mr-2",attrs:{"depressed":"","x-small":""}},[_vm._v("Скачать версию "+_vm._s(history.length - index))]):_c('VBtn',{staticClass:"mr-2",attrs:{"depressed":"","x-small":""}},[_vm._v("Запросить из архива")])]],2)])])}),1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }